import { Injectable } from '@angular/core';

import { RoutesService, isNullOrUndefined } from '@abp/ng.core';
import { ConfigStateService } from '@abp/ng.core';
import { tap } from 'rxjs/operators';

import { createEffect } from '@nexweb/shared/effects';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';

import {
  etsEnergySettlementMenuName,
  etsFinancialSettlementMenuName,
  etsOperationalReportingMenuName,
  operationalReportingMenuName,
} from './constants';

@Injectable({ providedIn: 'root' })
export class SetrepMenuEffects {
  operationalReportingMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.operationalReportingEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(operationalReportingMenuName, {
            invisible: !featureValue,
          });
          this.routes.patch(etsOperationalReportingMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  energySchedulingMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.energySettlementEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(etsEnergySettlementMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  financialSchedulingMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.financialSettlementEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(etsFinancialSettlementMenuName, {
            invisible: !featureValue,
          });
        }
      }),
    ),
  );

  constructor(
    private configStateService: ConfigStateService,
    private routes: RoutesService,
  ) {}
}
