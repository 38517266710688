import { ABP, eLayoutType } from '@abp/ng.core';

export const applicationRoutes: ABP.Route[] = [
  {
    path: '/',
    name: 'MenuHierarchy::MenuG:Home',
    iconClass: 'fas fa-home',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.MtpHome.Home.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:DayAhead',
    iconClass: 'fa fa-clock-o',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.DayAhead.Menu',
  },
  {
    path: '/amt/market-overview-X-dam',
    name: 'MenuHierarchy::MenuG:MarketOverviewDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: null,
    requiredPolicy: 'Nb.Amt.MarketOverviewDam.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:SingleOrder',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.SingleOrder.Menu',
  },
  {
    path: '/amt/order-form-X-dam',
    name: 'MenuHierarchy::MenuG:OrderFormDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SingleOrder',
    layout: null,
    requiredPolicy: 'Nb.Amt.OrderFormDam.Menu',
  },
  {
    path: '/amt/orders-all-portfolios-X-dam',
    name: 'MenuHierarchy::MenuG:OrdersAllPortfoliosDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SingleOrder',
    layout: null,
    requiredPolicy: 'Nb.Amt.OrdersAllPortfoliosDam.Menu',
  },
  {
    path: '/amt/portfolio-order-status-X-dam',
    name: 'MenuHierarchy::MenuG:PortfolioOrderStatusDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SingleOrder',
    layout: null,
    requiredPolicy: 'Nb.Amt.PortfolioOrderStatusDam.Menu',
  },
  {
    path: '/amt/portfolio-bid-curves-X-dam',
    name: 'MenuHierarchy::MenuG:PortfolioBidCurvesDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SingleOrder',
    layout: null,
    requiredPolicy: 'Nb.Amt.PortfolioBidCurvesDam.Menu',
  },
  {
    path: '/amt/amt-price-development-dashboard-X-dam',
    name: 'MenuHierarchy::MenuG:BidPointDashboardDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SingleOrder',
    layout: null,
    requiredPolicy: 'Nb.Amt.AmtPriceDevelopmentDashboardDam.Menu',
  },
  {
    path: '/amt/trading-process-X-dam',
    name: 'MenuHierarchy::MenuG:PriceCalculationDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: null,
    requiredPolicy: 'Nb.Amt.TradingProcessDam.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:MarketResultDam',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.MarketResultDam.Menu',
  },
  {
    path: '/amt/portfolio-schedule-X-dam',
    name: 'MenuHierarchy::MenuG:PortfolioScheduleDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultDam',
    layout: null,
    requiredPolicy: 'Nb.Amt.PortfolioScheduleDam.Menu',
  },
  {
    path: '/amt/schedules-all-portfolios-X-dam',
    name: 'MenuHierarchy::MenuG:SchedulesAllPortfoliosDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultDam',
    layout: null,
    requiredPolicy: 'Nb.Amt.SchedulesAllPortfoliosDam.Menu',
  },
  {
    path: '/amt/prices-and-turnover-X-dam',
    name: 'MenuHierarchy::MenuG:PricesAndTurnoverDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultDam',
    layout: null,
    requiredPolicy: 'Nb.Amt.PricesAndTurnoverDam.Menu',
  },
  {
    path: '/amt/trade-per-currency-X-dam',
    name: 'MenuHierarchy::MenuG:TradedAmountsDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultDam',
    layout: null,
    requiredPolicy: 'Nb.Amt.TradePerCurrencyDam.Menu',
  },
  {
    path: '/setrep/document-generation-X-dam',
    name: 'MenuHierarchy::MenuG:GenerateDocumentsDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultDam',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DocumentGenerationDam.Menu',
  },
  {
    path: '/amt/exact-prices-X-dam',
    name: 'MenuHierarchy::MenuG:ExactPricesDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: null,
    requiredPolicy: 'Nb.Amt.ExactPricesDam.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:MarketCrossDam',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.MarketCrossDam.Menu',
  },
  {
    path: '/amt/unconstrained-market-cross-X-dam',
    name: 'MenuHierarchy::MenuG:UnconstrainedMarketCrossDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketCrossDam',
    layout: null,
    requiredPolicy: 'Nb.Amt.UnconstrainedMarketCrossDam.Menu',
  },
  {
    path: '/amt/area-market-cross-X-dam',
    name: 'MenuHierarchy::MenuG:AreaMarketCrossDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketCrossDam',
    layout: null,
    requiredPolicy: 'Nb.Amt.AreaMarketCrossDam.Menu',
  },
  {
    path: '/tsam/amt-flow-X-dam',
    name: 'MenuHierarchy::MenuG:DamFlow',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: null,
    requiredPolicy: 'Nb.Tsam.AmtFlowDam.Menu',
  },
  {
    path: '/amt/curtailment-X-dam',
    name: 'MenuHierarchy::MenuG:CurtailmentDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: null,
    requiredPolicy: 'Nb.Amt.CurtailmentDam.Menu',
  },
  {
    path: '/amt/area-net-exchange-X-dam',
    name: 'MenuHierarchy::MenuG:AreaNetExchangeDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: null,
    requiredPolicy: 'Nb.Amt.AreaNetExchangeDam.Menu',
  },
  {
    path: '/emmd/portfolio-result-adjustment-X-dam',
    name: 'MenuHierarchy::MenuG:ResultAdjustmentsDam',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:DayAhead',
    layout: null,
    requiredPolicy: 'Nb.Emmd.PortfolioResultAdjustmentDam.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:Fpm',
    iconClass: 'fa fa-calendar',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.Fpm.Menu',
  },
  {
    path: '/amt/market-overview-X-fpm',
    name: 'MenuHierarchy::MenuG:MarketOverviewFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.MarketOverviewFpm.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:FpmOrders',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.FpmOrders.Menu',
  },
  {
    path: '/amt/order-form-X-fpm',
    name: 'MenuHierarchy::MenuG:OrderFormFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:FpmOrders',
    layout: null,
    requiredPolicy: 'Nb.Amt.OrderFormFpm.Menu',
  },
  {
    path: '/amt/orders-all-portfolios-X-fpm',
    name: 'MenuHierarchy::MenuG:OrdersAllPortfoliosFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:FpmOrders',
    layout: null,
    requiredPolicy: 'Nb.Amt.OrdersAllPortfoliosFpm.Menu',
  },
  {
    path: '/amt/portfolio-order-status-X-fpm',
    name: 'MenuHierarchy::MenuG:PortfolioOrderStatusFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:FpmOrders',
    layout: null,
    requiredPolicy: 'Nb.Amt.PortfolioOrderStatusFpm.Menu',
  },
  {
    path: '/amt/portfolio-bid-curves-X-fpm',
    name: 'MenuHierarchy::MenuG:PortfolioBidCurvesFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:FpmOrders',
    layout: null,
    requiredPolicy: 'Nb.Amt.PortfolioBidCurvesFpm.Menu',
  },
  {
    path: '/amt/amt-price-development-dashboard-X-fpm',
    name: 'MenuHierarchy::MenuG:BidPointDashboardFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:FpmOrders',
    layout: null,
    requiredPolicy: 'Nb.Amt.AmtPriceDevelopmentDashboardFpm.Menu',
  },
  {
    path: '/amt/trading-process-X-fpm',
    name: 'MenuHierarchy::MenuG:PriceCalculationFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.TradingProcessFpm.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:MarketResultFpm',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.MarketResultFpm.Menu',
  },
  {
    path: '/amt/portfolio-schedule-X-fpm',
    name: 'MenuHierarchy::MenuG:PortfolioScheduleFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultFpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.PortfolioScheduleFpm.Menu',
  },
  {
    path: '/amt/schedules-all-portfolios-X-fpm',
    name: 'MenuHierarchy::MenuG:SchedulesAllPortfoliosFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultFpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.SchedulesAllPortfoliosFpm.Menu',
  },
  {
    path: '/amt/prices-and-turnover-X-fpm',
    name: 'MenuHierarchy::MenuG:PricesAndTurnoverFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultFpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.PricesAndTurnoverFpm.Menu',
  },
  {
    path: '/amt/trade-per-currency-X-fpm',
    name: 'MenuHierarchy::MenuG:TradedAmountsFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultFpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.TradePerCurrencyFpm.Menu',
  },
  {
    path: '/setrep/document-generation-X-fpm',
    name: 'MenuHierarchy::MenuG:GenerateDocumentsFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketResultFpm',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DocumentGenerationFpm.Menu',
  },
  {
    path: '/amt/exact-prices-X-fpm',
    name: 'MenuHierarchy::MenuG:ExactPricesFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.ExactPricesFpm.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:MarketCrossFpm',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.MarketCrossFpm.Menu',
  },
  {
    path: '/amt/unconstrained-market-cross-X-fpm',
    name: 'MenuHierarchy::MenuG:UnconstrainedMarketCrossFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketCrossFpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.UnconstrainedMarketCrossFpm.Menu',
  },
  {
    path: '/amt/area-market-cross-X-fpm',
    name: 'MenuHierarchy::MenuG:AreaMarketCrossFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketCrossFpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.AreaMarketCrossFpm.Menu',
  },
  {
    path: '/tsam/amt-flow-X-fpm',
    name: 'MenuHierarchy::MenuG:FpmFlow',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: null,
    requiredPolicy: 'Nb.Tsam.AmtFlowFpm.Menu',
  },
  {
    path: '/amt/curtailment-X-fpm',
    name: 'MenuHierarchy::MenuG:CurtailmentFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.CurtailmentFpm.Menu',
  },
  {
    path: '/amt/area-net-exchange-X-fpm',
    name: 'MenuHierarchy::MenuG:AreaNetExchangeFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: null,
    requiredPolicy: 'Nb.Amt.AreaNetExchangeFpm.Menu',
  },
  {
    path: '/emmd/portfolio-result-adjustment-X-fpm',
    name: 'MenuHierarchy::MenuG:ResultAdjustmentsFpm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Fpm',
    layout: null,
    requiredPolicy: 'Nb.Emmd.PortfolioResultAdjustmentFpm.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:IntraDay',
    iconClass: 'fa fa-hourglass',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.IntraDay.Menu',
  },
  {
    path: '/cmt/bid-and-ask',
    name: 'MenuHierarchy::MenuG:BidAndAsk',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:IntraDay',
    layout: null,
    requiredPolicy: 'Nb.Cmt.BidAndAsk.Menu',
  },
  {
    path: '/tsam/view-atc-X-idm',
    name: 'MenuHierarchy::MenuG:ViewAtcIdm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:IntraDay',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ViewAtcIdm.Menu',
  },
  {
    path: '/tsam/manage-atc-X-idm',
    name: 'MenuHierarchy::MenuG:ManageAtcIdm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:IntraDay',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ManageAtcIdm.Menu',
  },
  {
    path: '/cmt/order-history',
    name: 'MenuHierarchy::MenuG:OrderHistory',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:IntraDay',
    layout: null,
    requiredPolicy: 'Nb.Cmt.OrderHistory.Menu',
  },
  {
    path: '/cmt/bid-validation-stats',
    name: 'MenuHierarchy::MenuG:BidValidationStatistics',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:IntraDay',
    layout: null,
    requiredPolicy: 'Nb.Cmt.BidValidationStats.Menu',
  },
  {
    path: '/cmt/result-adjustments',
    name: 'MenuHierarchy::MenuG:ResultAdjustmentsIdm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:IntraDay',
    layout: null,
    requiredPolicy: 'Nb.Cmt.ResultAdjustments.Menu',
  },
  {
    path: '/cmt/price-development-dashboard',
    name: 'MenuHierarchy::MenuG:PriceDevelopmentDashboard',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:IntraDay',
    layout: null,
    requiredPolicy: 'Nb.Cmt.PriceDevelopmentDashboard.Menu',
  },
  {
    path: '/setrep/document-generation-X-idm',
    name: 'MenuHierarchy::MenuG:GenerateDocumentsIntraDay',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:IntraDay',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DocumentGenerationIdm.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:BalancingMarket',
    iconClass: 'fa fa-chart-bar',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.BalancingMarket.Menu',
  },
  {
    path: '/bm/order-book',
    name: 'MenuHierarchy::MenuG:OrderBook',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BalancingMarket',
    layout: null,
    requiredPolicy: 'Nb.Bm.OrderBook.Menu',
  },
  {
    path: '/bm/merit-order-stack',
    name: 'MenuHierarchy::MenuG:MeritOrderStack',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BalancingMarket',
    layout: null,
    requiredPolicy: 'Nb.Bm.MeritOrderStack.Menu',
  },
  {
    path: '/tsam/view-atc-X-bm',
    name: 'MenuHierarchy::MenuG:ViewAtcBm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BalancingMarket',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ViewAtcBm.Menu',
  },
  {
    path: '/tsam/manage-atc-X-bm',
    name: 'MenuHierarchy::MenuG:ManageAtcBm',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BalancingMarket',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ManageAtcBm.Menu',
  },
  {
    path: '/bm/tso-report-X-requesting-tso',
    name: 'MenuHierarchy::MenuG:RequestingTsoReport',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BalancingMarket',
    layout: null,
    requiredPolicy: 'Nb.Bm.TsoReportRequestingTso.Menu',
  },
  {
    path: '/bm/tso-report-X-supplying-tso',
    name: 'MenuHierarchy::MenuG:SupplyingTsoReport',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BalancingMarket',
    layout: null,
    requiredPolicy: 'Nb.Bm.TsoReportSupplyingTso.Menu',
  },
  {
    path: '/setrep/document-generation-X-bm',
    name: 'MenuHierarchy::MenuG:GenerateDocumentsBM',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BalancingMarket',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DocumentGenerationBm.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:ManageMarkets',
    iconClass: 'fa fa-university',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.ManageMarkets.Menu',
  },
  {
    path: '/emmd/view-currency-rates',
    name: 'MenuHierarchy::MenuG:ViewCurrencyRate',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageMarkets',
    layout: null,
    requiredPolicy: 'Nb.Emmd.ViewCurrencyRates.Menu',
  },
  {
    path: '/tsam/intercon-gen-event-register',
    name: 'MenuHierarchy::MenuG:InterconAndGenEventRegister',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageMarkets',
    layout: null,
    requiredPolicy: 'Nb.Tsam.InterconGenEventRegister.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:ManageMarkets',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.ManageInterconCapacity.Menu',
  },
  {
    path: '/tsam/manage-intercon-prod-sched',
    name: 'MenuHierarchy::MenuG:ManageInterconProductSchedules',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ManageInterconProdSched.Menu',
  },
  {
    path: '/tsam/manage-missing-intercon-prod-sched',
    name: 'MenuHierarchy::MenuG:ManageMissingCapacities',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ManageMissingInterconProdSched.Menu',
  },
  {
    path: '/tsam/view-invalid-atc',
    name: 'MenuHierarchy::MenuG:ViewInvalidAtcs',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ViewInvalidAtc.Menu',
  },
  {
    path: '/tsam/manage-ttc',
    name: 'MenuHierarchy::MenuG:ManageTtc',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ManageTtc.Menu',
  },
  {
    path: '/tsam/intercon-event',
    name: 'MenuHierarchy::MenuG:ManageInterconEvents',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    layout: null,
    requiredPolicy: 'Nb.Tsam.InterconEvent.Menu',
  },
  {
    path: '/tsam/generation-event',
    name: 'MenuHierarchy::MenuG:ManageGenerationEvents',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    layout: null,
    requiredPolicy: 'Nb.Tsam.GenerationEvent.Menu',
  },
  {
    path: '/tsam/ttc-deviation-dashboard',
    name: 'MenuHierarchy::MenuG:TtcDeviationDashboard',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    layout: null,
    requiredPolicy: 'Nb.Tsam.TtcDeviationDashboard.Menu',
  },
  {
    path: '/tsam/ttc-deviation-stats',
    name: 'MenuHierarchy::MenuG:TtcDeviationStats',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageInterconCapacity',
    layout: null,
    requiredPolicy: 'Nb.Tsam.TtcDeviationStats.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:InterconCapacityAllocation',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:ManageMarkets',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.InterconCapacityAllocation.Menu',
  },
  {
    path: '/tsam/view-intercon-prod-sched',
    name: 'MenuHierarchy::MenuG:ViewInterconProductSchedules',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:InterconCapacityAllocation',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ViewInterconProdSched.Menu',
  },
  {
    path: '/tsam/view-intercon-interval-sched',
    name: 'MenuHierarchy::MenuG:ViewInterconIntervalSchedules',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:InterconCapacityAllocation',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ViewInterconIntervalSched.Menu',
  },
  {
    path: '/tsam/intercon-allocation-dashboard',
    name: 'MenuHierarchy::MenuG:InterconAllocationDashboard',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:InterconCapacityAllocation',
    layout: null,
    requiredPolicy: 'Nb.Tsam.InterconAllocationDashboard.Menu',
  },
  {
    path: '/tsam/scada-intercon-flow',
    name: 'MenuHierarchy::MenuG:ScadaInterconEnergyMeasurement',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:InterconCapacityAllocation',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ScadaInterconFlow.Menu',
  },
  {
    path: '/tsam/sched-vs-scada-flows',
    name: 'MenuHierarchy::MenuG:ScheduledVsScadaMeasurement',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:InterconCapacityAllocation',
    layout: null,
    requiredPolicy: 'Nb.Tsam.SchedVsScadaFlows.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:ScheduleAdjustments',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:ManageMarkets',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.ScheduleAdjustments.Menu',
  },
  {
    path: '/tsam/mtp-flow-adjustments',
    name: 'MenuHierarchy::MenuG:ManageFlowAdjustments',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ScheduleAdjustments',
    layout: null,
    requiredPolicy: 'Nb.Tsam.MtpFlowAdjustments.Menu',
  },
  {
    path: '/tsam/manage-cntrl-area-imbalance-sched',
    name: 'MenuHierarchy::MenuG:ManageImbalanceSchedules',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:ManageMarkets',
    layout: null,
    requiredPolicy: 'Nb.Tsam.ManageCntrlAreaImbalanceSched.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:BilateralContracting',
    iconClass: 'fa fa-gear',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.BilateralContracting.Menu',
  },
  {
    path: '/tsam/bilateral-schedules',
    name: 'MenuHierarchy::MenuG:BilateralSchedules',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BilateralContracting',
    layout: null,
    requiredPolicy: 'Nb.Tsam.BilateralSchedules.Menu',
  },
  {
    path: '/tsam/final-bilateral-nomination',
    name: 'MenuHierarchy::MenuG:FinalBilateralNomination',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BilateralContracting',
    layout: null,
    requiredPolicy: 'Nb.Tsam.FinalBilateralNomination.Menu',
  },
  {
    path: '/tsam/emergency-energy-nomination',
    name: 'MenuHierarchy::MenuG:EmergencyEnergyNomination',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:BilateralContracting',
    layout: null,
    requiredPolicy: 'Nb.Tsam.EmergencyEnergyNomination.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:Settlements',
    iconClass: 'fa fa-calculator',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.Settlements.Menu',
  },
  {
    path: '/setrep/document-type',
    name: 'MenuHierarchy::MenuG:DocumentTypes',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Settlements',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DocumentType.Menu',
  },
  {
    path: '/setrep/data-entity',
    name: 'MenuHierarchy::MenuG:DataEntities',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Settlements',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DataEntity.Menu',
  },
  {
    path: '/setrep/settlement-parameter',
    name: 'MenuHierarchy::MenuG:SettlementParameters',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Settlements',
    layout: null,
    requiredPolicy: 'Nb.Setrep.SettlementParameter.Menu',
  },
  {
    path: '/setrep/manage-settlements',
    name: 'MenuHierarchy::MenuG:ManageSettlements',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Settlements',
    layout: null,
    requiredPolicy: 'Nb.Setrep.ManageSettlements.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:SettlementResults',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Settlements',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.SettlementResults.Menu',
  },
  {
    path: '/setrep/settlement-results-X-portfolio',
    name: 'MenuHierarchy::MenuG:PortfolioResults',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SettlementResults',
    layout: null,
    requiredPolicy: 'Nb.Setrep.SettlementResultsPortfolio.Menu',
  },
  {
    path: '/setrep/settlement-results-X-area',
    name: 'MenuHierarchy::MenuG:AreaResults',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SettlementResults',
    layout: null,
    requiredPolicy: 'Nb.Setrep.SettlementResultsArea.Menu',
  },
  {
    path: '/setrep/settlement-results-X-company',
    name: 'MenuHierarchy::MenuG:CompanyResults',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SettlementResults',
    layout: null,
    requiredPolicy: 'Nb.Setrep.SettlementResultsCompany.Menu',
  },
  {
    path: '/setrep/document-generation',
    name: 'MenuHierarchy::MenuG:GenerateDocuments',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Settlements',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DocumentGeneration.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:OperatingReporting',
    iconClass: 'fa fa-chart-bar',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.OperatingReporting.Menu',
  },
  {
    path: '/setrep/settlement-parameter-X-operating-reporting',
    name: 'MenuHierarchy::MenuG:SettlementParametersOperatingReporting',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:OperatingReporting',
    layout: null,
    requiredPolicy: 'Nb.Setrep.SettlementParameterOperatingReporting.Menu',
  },
  {
    path: '/setrep/manage-settlements-X-operating-reporting',
    name: 'MenuHierarchy::MenuG:ManageSettlementsOperatingReporting',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:OperatingReporting',
    layout: null,
    requiredPolicy: 'Nb.Setrep.ManageSettlementsOperatingReporting.Menu',
  },
  {
    path: '/setrep/document-generation-X-operating-reporting',
    name: 'MenuHierarchy::MenuG:GenerateDocumentsOperatingReporting',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:OperatingReporting',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DocumentGenerationOperatingReporting.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:Configuration',
    iconClass: 'fas fa-tools',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.Configuration.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:AccessControl',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Configuration',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.AccessControl.Menu',
  },
  {
    path: '/aum/company-X-access',
    name: 'MenuHierarchy::MenuG:CompaniesAccess',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:AccessControl',
    layout: null,
    requiredPolicy: 'Nb.Aum.CompanyAccess.Menu',
  },
  {
    path: '/aum/user',
    name: 'MenuHierarchy::MenuG:Users',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:AccessControl',
    layout: null,
    requiredPolicy: 'Nb.Aum.User.Menu',
  },
  {
    path: '/aum/user-activity-log-X-all-user',
    name: 'MenuHierarchy::MenuG:AccessLogs',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:AccessControl',
    layout: null,
    requiredPolicy: 'Nb.Aum.UserActivityLogAllUser.Menu',
  },
  {
    path: '/samm/module-parameter-access',
    name: 'MenuHierarchy::MenuG:ModuleParameterAccess',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:AccessControl',
    layout: null,
    requiredPolicy: 'Nb.Samm.ModuleParameterAccess.Menu',
  },
  {
    path: '/samm/application-parameter-access',
    name: 'MenuHierarchy::MenuG:ApplicationParameterAccess',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:AccessControl',
    layout: null,
    requiredPolicy: 'Nb.Samm.ApplicationParameterAccess.Menu',
  },
  {
    path: '/aum/user-activity-log-X-user-login-monitor',
    name: 'MenuHierarchy::MenuG:UserLoginActivity',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:AccessControl',
    layout: null,
    requiredPolicy: 'Nb.Aum.UserActivityLogUserLoginMonitor.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:SolutionParameters',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Configuration',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.SolutionParameters.Menu',
  },
  {
    path: '/samm/module-parameter-value',
    name: 'MenuHierarchy::MenuG:ModuleParameters',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SolutionParameters',
    layout: null,
    requiredPolicy: 'Nb.Samm.ModuleParameterValue.Menu',
  },
  {
    path: '/samm/application-parameter-value',
    name: 'MenuHierarchy::MenuG:ApplicationParameters',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SolutionParameters',
    layout: null,
    requiredPolicy: 'Nb.Samm.ApplicationParameterValue.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:TxAllocationManagement',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Configuration',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.TxAllocationManagement.Menu',
  },
  {
    path: '/tsam/country-management',
    name: 'MenuHierarchy::MenuG:Countries',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:TxAllocationManagement',
    layout: null,
    requiredPolicy: 'Nb.Tsam.CountryManagement.Menu',
  },
  {
    path: '/tsam/area-management',
    name: 'MenuHierarchy::MenuG:Areas',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:TxAllocationManagement',
    layout: null,
    requiredPolicy: 'Nb.Tsam.AreaManagement.Menu',
  },
  {
    path: '/tsam/interconnector-management',
    name: 'MenuHierarchy::MenuG:Interconnectors',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:TxAllocationManagement',
    layout: null,
    requiredPolicy: 'Nb.Tsam.InterconnectorManagement.Menu',
  },
  {
    path: '/tsam/transmission-path-management',
    name: 'MenuHierarchy::MenuG:TxPaths',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:TxAllocationManagement',
    layout: null,
    requiredPolicy: 'Nb.Tsam.TransmissionPathManagement.Menu',
  },
  {
    path: '/tsam/bilateral-contract-management',
    name: 'MenuHierarchy::MenuG:BilateralContracts',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:TxAllocationManagement',
    layout: null,
    requiredPolicy: 'Nb.Tsam.BilateralContractManagement.Menu',
  },
  {
    path: '/tsam/file-upload-log',
    name: 'MenuHierarchy::MenuG:FileUploadLog',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:TxAllocationManagement',
    layout: null,
    requiredPolicy: 'Nb.Tsam.FileUploadLog.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:MasterData',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Configuration',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.MasterData.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:Markets',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:MasterData',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.Markets.Menu',
  },
  {
    path: '/emmd/market-product-definition',
    name: 'MenuHierarchy::MenuG:MarketProductDefinition',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Markets',
    layout: null,
    requiredPolicy: 'Nb.Emmd.MarketProductDefinition.Menu',
  },
  {
    path: '/emmd/portfolio-management',
    name: 'MenuHierarchy::MenuG:Portfolios',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MasterData',
    layout: null,
    requiredPolicy: 'Nb.Emmd.PortfolioManagement.Menu',
  },
  {
    path: '/cal/calendar-management',
    name: 'MenuHierarchy::MenuG:Calendar',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MasterData',
    layout: null,
    requiredPolicy: 'Nb.Cal.CalendarManagement.Menu',
  },
  {
    path: '/aum/company-X-master-data',
    name: 'MenuHierarchy::MenuG:CompaniesMasterData',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MasterData',
    layout: null,
    requiredPolicy: 'Nb.Aum.CompanyMasterData.Menu',
  },
  {
    path: '/tou/tou-management',
    name: 'MenuHierarchy::MenuG:TouProductDefinition',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Configuration',
    layout: null,
    requiredPolicy: 'Nb.Tou.TouManagement.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:SettlementAndReportingConfiguration',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:Configuration',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.SettlementAndReportingConfiguration.Menu',
  },
  {
    path: '/setrep/document-type-X-configuration',
    name: 'MenuHierarchy::MenuG:DocumentTypesConfiguration',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SettlementAndReportingConfiguration',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DocumentTypeConfiguration.Menu',
  },
  {
    path: '/setrep/data-entity-X-configuration',
    name: 'MenuHierarchy::MenuG:DataEntitiesConfiguration',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SettlementAndReportingConfiguration',
    layout: null,
    requiredPolicy: 'Nb.Setrep.DataEntityConfiguration.Menu',
  },
  {
    path: '/setrep/settlement-parameter-X-configuration',
    name: 'MenuHierarchy::MenuG:SettlementParametersConfiguration',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:SettlementAndReportingConfiguration',
    layout: null,
    requiredPolicy: 'Nb.Setrep.SettlementParameterConfiguration.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:MarketSurveillance',
    iconClass: 'fa fa-gear',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.MarketSurveillance.Menu',
  },
  {
    path: '/smas/shiny-dashboard',
    name: 'MenuHierarchy::MenuG:ShinyDashboard',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketSurveillance',
    layout: null,
    requiredPolicy: 'Nb.Smas.ShinyDashboard.Menu',
  },
  {
    path: '/smas/market-watchlists',
    name: 'MenuHierarchy::MenuG:MarketWatchlists',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketSurveillance',
    layout: null,
    requiredPolicy: 'Nb.Smas.MarketWatchlists.Menu',
  },
  {
    path: '/smas/market-incidents-dashboard',
    name: 'MenuHierarchy::MenuG:MarketIncidents',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketSurveillance',
    layout: null,
    requiredPolicy: 'Nb.Smas.MarketIncidentsDashboard.Menu',
  },
  {
    path: '/smas/market-cases',
    name: 'MenuHierarchy::MenuG:MarketCases',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketSurveillance',
    layout: null,
    requiredPolicy: 'Nb.Smas.MarketCases.Menu',
  },
  {
    path: '/smas/market-cases-stats',
    name: 'MenuHierarchy::MenuG:CaseStatistics',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:MarketSurveillance',
    layout: null,
    requiredPolicy: 'Nb.Smas.MarketCasesStats.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:Administration',
    iconClass: 'fa fa-gear',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.Administration.Menu',
  },
  {
    path: '/mdd/message-inbox',
    name: 'MenuHierarchy::MenuG:MessageInbox',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Administration',
    layout: null,
    requiredPolicy: 'Nb.Mdd.MessageInbox.Menu',
  },
  {
    path: '/smas/tickets',
    name: 'MenuHierarchy::MenuG:SupportTickets',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Administration',
    layout: null,
    requiredPolicy: 'Nb.Smas.Tickets.Menu',
  },
  {
    path: '/smas/ticket-statistics',
    name: 'MenuHierarchy::MenuG:TicketStatistics',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Administration',
    layout: null,
    requiredPolicy: 'Nb.Smas.TicketStatistics.Menu',
  },
  {
    path: '/nav-file-management',
    name: 'MenuHierarchy::MenuG:FileManagement',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Administration',
    layout: null,
    requiredPolicy: 'Nb.FileManagement.AbpFileManagement.Menu',
  },
  {
    path: '/scheduler/scheduler-dashboard',
    name: 'MenuHierarchy::MenuG:BackgroundProcesses',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Administration',
    layout: null,
    requiredPolicy: 'Nb.Scheduler.SchedulerDashboard.Menu',
  },
  {
    path: '/bulkdownload/bulk-download-log',
    name: 'MenuHierarchy::MenuG:BulkDownloadLog',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Administration',
    layout: null,
    requiredPolicy: 'Nb.BulkDownload.BulkDownloadLog.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:ThirdPartyIntegration',
    iconClass: 'fas fa-file-import',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.ThirdPartyIntegration.Menu',
  },
  {
    path: '',
    name: 'MenuHierarchy::MenuG:Scada',
    iconClass: '',
    parentName: 'MenuHierarchy::MenuG:ThirdPartyIntegration',
    layout: eLayoutType.application,
    requiredPolicy: 'Nb.Nav.Scada.Menu',
  },
  {
    path: '/scada/scheduler-log',
    name: 'MenuHierarchy::MenuG:ScadaSchedulerLog',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Scada',
    layout: null,
    requiredPolicy: 'Nb.SappMtp.SchedulerLog.Menu',
  },
  {
    path: '/scada/file-load-log',
    name: 'MenuHierarchy::MenuG:ScadaFileLoadLog',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Scada',
    layout: null,
    requiredPolicy: 'Nb.SappMtp.FileLoadLog.Menu',
  },
  {
    path: '/scada/file-generate-log',
    name: 'MenuHierarchy::MenuG:ScadaFileGenerateLog',
    iconClass: null,
    parentName: 'MenuHierarchy::MenuG:Scada',
    layout: null,
    requiredPolicy: 'Nb.SappMtp.FileGenerateLog.Menu',
  },
];
