import { Routes } from '@angular/router';

import { authGuard } from '@abp/ng.core';

export const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('@volo/abp.ng.account/public').then((m) => m.AccountPublicModule.forLazy()),
  },
  {
    path: '',
    canActivate: [authGuard],
    children: [
      {
        path: 'identity',
        loadChildren: () => import('@volo/abp.ng.identity').then((m) => m.IdentityModule.forLazy()),
      },
      {
        path: 'language-management',
        loadChildren: () =>
          import('@volo/abp.ng.language-management').then((m) => m.LanguageManagementModule.forLazy()),
      },
      {
        path: 'saas',
        loadChildren: () => import('@volo/abp.ng.saas').then((m) => m.SaasModule.forLazy()),
      },
      {
        path: 'audit-logs',
        loadChildren: () => import('@volo/abp.ng.audit-logging').then((m) => m.AuditLoggingModule.forLazy()),
      },
      {
        path: 'openiddict',
        loadChildren: () => import('@volo/abp.ng.openiddictpro').then((m) => m.OpeniddictproModule.forLazy()),
      },
      {
        path: 'text-template-management',
        loadChildren: () =>
          import('@volo/abp.ng.text-template-management').then((m) => m.TextTemplateManagementModule.forLazy()),
      },
      {
        path: 'setting-management',
        loadChildren: () => import('@abp/ng.setting-management').then((m) => m.SettingManagementModule.forLazy()),
      },
      {
        path: 'chat',
        loadChildren: () => import('@volo/abp.ng.chat').then((m) => m.ChatModule.forLazy()),
      },
      {
        path: 'nav-file-management',
        loadChildren: () => import('@volo/abp.ng.file-management').then((m) => m.FileManagementModule.forLazy()),
      },
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('@nexweb/mtp/feature-home').then((m) => m.routes),
      },
      {
        path: 'amt',
        loadChildren: () => import('@nexweb/amt/feature-amt').then((m) => m.AmtFeatureAmtModule),
      },
      {
        path: 'setrep',
        loadChildren: () => import('@nexweb/setrep/feature/shell').then((m) => m.SetrepFeatureShellModule),
      },
      {
        path: 'tsam',
        loadChildren: () => import('@nexweb/tsam/feature-tsam').then((m) => m.TsamFeatureTsamModule),
      },
      {
        path: 'emmd',
        loadChildren: () => import('@nexweb/emmd/feature/shell').then((m) => m.EmmdFeatureShellModule),
      },
      {
        path: 'cmt',
        loadChildren: () => import('@nexweb/cmt/feature-cmt').then((m) => m.CmtFeatureCmtModule),
      },
      {
        path: 'bm',
        loadChildren: () => import('@nexweb/bm/feature-bm').then((m) => m.BmFeatureBmModule),
      },
      {
        path: 'aum',
        loadChildren: () => import('@nexweb/aum/feature/shell').then((m) => m.AumFeatureShellModule),
      },
      {
        path: 'samm',
        loadChildren: () => import('@nexweb/samm/feature-samm').then((m) => m.SammFeatureSammModule),
      },
      {
        path: 'cal',
        loadChildren: () => import('@nexweb/cal/feature/shell').then((m) => m.CalFeatureShellModule),
      },
      {
        path: 'tou',
        loadChildren: () => import('@nexweb/tou/feature/shell').then((m) => m.TouFeatureShellModule),
      },
      {
        path: 'smas',
        loadChildren: () => import('@nexweb/smas/feature/shell').then((m) => m.SmasFeatureShellModule),
      },
      {
        path: 'mdd',
        loadChildren: () => import('@nexweb/mdd/feature/shell').then((m) => m.MddFeatureShellModule),
      },
      {
        path: 'scheduler',
        loadChildren: () => import('@nexweb/scheduler/feature/shell').then((m) => m.SchedulerFeatureShellModule),
      },
      {
        path: 'bulkdownload',
        loadChildren: () => import('@nexweb/bulk-download/feature/shell').then((m) => m.BulkDownloadFeatureShellModule),
      },
      {
        path: 'scada',
        loadChildren: () => import('@nexweb/sappmtp/feature/shell').then((m) => m.SappMtpShellModule),
      },
    ],
  },
];
